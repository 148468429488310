<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        d="M20.56262207 4.29425049c.57641602.55245972 1.116333 1.13619995 1.66552734 1.71328735.3371582.35534668.64880371.72872925.77185059 1.22052002v.54489136c-.1361084.53253174-.506958.90969849-.85351563 1.302948-1.484375 1.68862915-3.14904785 3.16027832-5.0982666 4.28604126-1.34460449.77893066-2.77172851 1.34182739-4.3190918 1.55407715-1.53320312.2113037-3.02697753.01611328-4.4822998-.4984436-2.14355469-.75808717-3.99328613-2.01556397-5.6439209-3.56680299C1.85461426 10.146698 1.16259766 9.3791504.4753418 8.61346436c-.64318848-.7173462-.62438965-1.50100708 0-2.23919678C1.97949219 4.59277344 3.67333984 3.02920532 5.6619873 1.8086853 7.02148438.97384644 8.46740723.3607483 10.0513916.11529541c1.77075195-.27575684 3.48718262-.05114746 5.1508789.6121521 2.03564454.80737305 3.77917481 2.06768799 5.36035157 3.56680298zm-5.64111328 8.53796387c2.64587402-1.14471436 4.73498535-3.02096558 6.58935547-5.1947937.09484863-.11181641.069458-.19613648-.01782227-.2975464-1.65344238-1.95016479-3.5180664-3.64450072-5.80065918-4.81954955-.16516113-.0840149-.33081054-.16455078-.4967041-.24163819-.40112305-.18432617-.81347656-.34259033-1.23461914-.4737854-.09863281-.03128051-.19812012-.05969238-.29858398-.08718872-.70410157-.19284057-1.42993164-.29257202-2.1595459-.29660034-1.06640625.00949097-2.1217041.21865845-3.11242676.61688232l-.19812012.08056641-.2113037.09475708c-2.59326173 1.15133667-4.64758302 2.99728394-6.47473145 5.1369934-.10144043.12316895-.07800293.2046814.01586914.31271363 1.62438965 1.91036988 3.45336914 3.57910156 5.6842041 4.75320435 1.2121582.63867187 2.49194336 1.06036377 3.86560058 1.14282226 1.34265137.07958985 2.62890625-.19711303 3.8494873-.72683715z"
      />
      <path
        fill-rule="evenodd"
        d="M6.93237305 5.58068848c.76818847-1.8670044 2.57507324-3.08312989 4.57800293-3.08120728 1.31335449.00024414 2.57263183.52740479 3.50061035 1.46536255.92785644.93795776 1.44836426 2.2097168 1.4465332 3.53518677-.00109863 2.0215454-1.2088623 3.84329223-3.05981445 4.61569214-1.85107422.77236938-3.98083496.34320068-5.39611817-1.08734131-1.4152832-1.43051148-1.83728027-3.58068848-1.06921386-5.44769287zm1.03173828 1.91491699c-.00134278 1.97445679 1.58337402 3.57626343 3.53967285 3.57785034C13.45703125 11.06619263 15.03942871 9.47131348 15.04919434 7.5c.00097656-1.97445679-1.58398438-3.57598877-3.54040528-3.57720947-1.95629883-.0012207-3.54333496 1.59832763-3.54467773 3.57281494z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 23 15" },
    width: { type: String, default: "23" },
    height: { type: String, default: "15" },
    color: { type: String, default: "#000" },
  },
};
</script>