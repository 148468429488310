<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        d="M10.85717773 0L12 1.1111145h3c.5522461 0 1 .4477234 1 1v.222229c0 .55227662-.4477539 1-1 1H1c-.5522461 0-1-.44772338-1-1v-.222229c0-.5522766.4477539-1 1-1h3L5.14282227 0h5.71435546zM1.14282227 17.77780151C1.14282227 19 2.17138672 20 3.42858887 20h9.14282226c1.25708008 0 2.28564453-1 2.28564453-2.22219849V5.44445801c0-.55227661-.44763183-1-1-1H2.14282226c-.55224609 0-1 .44772339-1 1v12.3333435zM3.42858887 6.6666565h9.14282226V17.777771H3.42858887V6.6666565z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 16 20" },
    width: { type: String, default: "16" },
    height: { type: String, default: "20" },
    color: { type: String, default: "#4c4c4c" },
  },
};
</script>